<template>
  <section class="faq">
    <h4 class="section-title mb-4">Часто задаваемые вопросы</h4>
    <div v-if="list.length" class="accordion" role="tablist">
      <b-card v-for="item in list" :key="item.id" no-body class="mb-2">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button @click="showAccordion(item.id)" block variant="secondary"> {{ item.question }}? </b-button>
        </b-card-header>
        <b-collapse v-model="item.isVisible" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>{{ item.answer }}</b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </section>
</template>

<script>
import Api from '@/services/api'

export default {
  name: 'FAQPage',
  data: () => ({
    list: [],
  }),

  computed: {
    visibleAccordion() {
      return this.list.find((el) => el.isVisible)
    },
  },

  async mounted() {
    const { faq } = await this.wrapAndHandleRequest(Api.fetchFaq)
    faq.forEach((el) => (el.isVisible = false))
    this.list = faq
  },

  methods: {
    showAccordion(id) {
      if (this.visibleAccordion && this.visibleAccordion.id === id) {
        return (this.visibleAccordion.isVisible = false)
      }
      this.list.forEach((el) => {
        el.id === id ? (el.isVisible = true) : (el.isVisible = false)
      })
    },
  },
}
</script>
